<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="font-weight-bolder">
          {{ $t("accountChartTitle") }}
        </b-card-title>
        <b-card-sub-title class="mt-25">
          {{ $t("accountChartDesc") }}
        </b-card-sub-title>
      </div>

      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <v-select
          v-model="period"
          :reduce="(val) => val.value"
          :options="periodOptions"
          class="ml-1"
          style="width: 250px"
          :clearable="false"
          @input="loadPeriod(period)"
        />
      </div>

      <div>
        <b-card-title class="font-weight-bolder float-right">
          <table class="table mb-0 float-right">
            <tbody v-if="series[0]">
              <tr>
                <td>{{ $t("message.chart.entry") }}</td>
                <td style="color: #6ab187">
                  {{ currencyValue(sumValue(positive)) }}
                </td>
              </tr>
              <tr>
                <td>{{ $t("message.chart.leaft") }}</td>
                <td style="color: #ac3e31">
                  {{ currencyValue(sumValue(negativeToCard)) }}
                </td>
              </tr>
              <tr>
                <th>{{ $t("message.chart.result") }}</th>
                <th>
                  {{
                    currencyValue(
                      resultValue(sumValue(positive), sumValue(negativeToCard))
                    )
                  }}
                </th>
              </tr>
            </tbody>
          </table>
        </b-card-title>
      </div>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        :height="chart.height"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import store from "@/store";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    vSelect,
  },

  data() {
    return {
      chart: {},
      series: [],
      chartOptions: {},

      chartLabels: [],
      positive: [],
      negative: [],
      negativeToCard: [],
      total: [],

      chartTimestamp: "",

      userData: store.state.user.userData,

      period: "all",
      periodOptions: [],
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadChartData();
    },
  },

  async created() {
    await this.loadChartData();
  },

  methods: {
    loadPeriod(period) {
      if (period === "all") {
        this.period = "all";
      } else {
        this.period = period;
      }

      this.loadChartData();
    },

    sumValue(value) {
      return value.reduce((a, b) => a + b, 0);
    },

    resultValue(a, b) {
      return a + b;
    },

    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat != "Invalid date" && newFormat != null) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    async loadChartData() {
      var vm = this;

      await axios
        .get(`${URL_API}chart/accounts/${this.period}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          var data = JSON.parse(response.data.chartInfo); // Json to object

          if (data != null) {
            if (this.period === "all") {
              this.periodOptions = [];
              for (var i = 0; i < data[2].length; i++) {
                this.periodOptions.push({
                  label: data[2][i],
                  value: data[2][i],
                });
              }
              this.periodOptions.push({
                label: vm.getColLabel("all"),
                value: "all",
              });
            }
            var keys = Object.keys(data[0]);
            var values = Object.values(data[0]);
            var values2 = Object.values(data[1]);

            this.negativeToCard = [];
            this.chartLabels = [];
            this.positive = [];
            this.negative = [];
            this.total = [];

            this.chartLabels = keys;
            this.negativeToCard = values2; //negative values to card

            this.positive = values; //positive values

            for (var i = 0; i < values2.length; i++) {
              //negative values to positive values
              if (values2[i] < 0) {
                this.negative.push(Math.abs(values2[i]));
              } else {
                this.negative.push(values2[i]);
              }
            }

            var sum = values.map(function (num, idx) {
              // sum of positive and negative to have the total
              return num + values2[idx];
            });

            this.total = sum;

            this.chartTimestamp = response.data.timestamp;
          }
        });

      this.chart = {
        height: "500px",
      };

      this.series = [
        {
          name: "Crédito",
          data: this.positive,
        },
        {
          name: "Débito",
          data: this.negative,
        },
        {
          name: "Saldo",
          data: this.total,
        },
      ];

      this.chartOptions = {
        chart: {
          type: "bar",
          height: 440,
          stacked: true,
        },

        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "80%",
          },
        },

        title: {
          text: vm.localeDate(this.chartTimestamp),
          align: "right",
          margin: 10,
          offsetX: 0,
          offsetY: 25,
          floating: false,
          style: {
            fontSize: "12px",
            fontWeight: "normal",
          },
        },

        colors: ["#00E396", "#e32500", "#004ce3"],

        legend: {
          show: true,
        },

        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: (val) => {
            return this.currencyValue(val);
          },
          textAnchor: "middle",
          distributed: false,
          offsetX: -35,
          offsetY: 0,
          background: {
            enabled: true,
            foreColor: "#fff",
            borderWidth: 0,
          },
        },

        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },

        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
          },
          y: {
            formatter: (value) => {
              return this.currencyValue(value);
            },
          },
        },

        xaxis: {
          categories: this.chartLabels,
          labels: {
            show: true,
            formatter: (value) => {
              return this.currencyValue(value);
            },
          },
        },
      };
    },

    getAutoHeight(value) {
      if (value === 0) {
        return 600;
      } else if (value === 1) {
        return 2 * 100;
      } else {
        return value * 120;
      }
    },

    getColLabel(colName) {
      return this.$i18n.t("message.chart." + colName);
    },

    currencyValue(value) {
      return this.$i18n.n(value, "currency");
    },
  },
};
</script>
